import React from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import HomePage from './Screens/Home';
import ProfilePage from './Screens/Profile';
import AboutPage from './Screens/About'; // Ensure this component exists
import SignInPage from './Screens/SignIn'; // Assuming you have a SignIn component
import { auth } from './firebase-config'; // Adjust this import based on your setup

const Drawer = createDrawerNavigator();

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'white', // Set your primary color here
    background: 'white', // Set background color here
    card: '#12CC8E', // Set card color here
    text: 'white', // Set text color here
  },
};

const App = () => {
  return (
    <NavigationContainer theme={MyTheme} >
      <Drawer.Navigator initialRouteName="LilyPad"
        screenOptions={{ drawerLabelStyle: {fontWeight: 'bold', fontSize: 24}, headerTitleStyle: { fontWeight: 'bold', fontSize: 32, }}}      
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        <Drawer.Screen name="LilyPad" component={HomePage} />
        <Drawer.Screen name="Profile" component={ProfilePage} />
        <Drawer.Screen name="About" component={AboutPage} />
        <Drawer.Screen name="Sign In" component={SignInPage} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

const CustomDrawerContent = (props) => {
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      props.navigation.navigate('Sign In');
    } catch (error) {
      console.error('Sign out error', error);
    }
  };

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      <DrawerItem label="Sign Out" onPress={handleSignOut} labelStyle={{ color: '#cc7075', fontWeight: 'bold', fontSize: 24 }} />
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#12CC8E',
  }
});

export default App;
