import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, Image, Dimensions } from 'react-native';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useNavigation } from '@react-navigation/native';

const colours = {
    primary: '#12CC8E'
};

const SignIn = () => {
    const navigation = useNavigation();
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleAuth = async (e) => {
        e.preventDefault();
        const action = isSigningUp ? createUserWithEmailAndPassword : signInWithEmailAndPassword;
        try {
            await action(auth, email, password);
            console.log(isSigningUp ? 'User registered successfully' : 'User signed in successfully');
            navigation.navigate('LilyPad'); // Redirect to Home page
        } catch (error) {
            setError(error.message);
            console.error('Authentication error:', error);
        }
    };

    return (
        <View style={styles.container}>
            <Image source={require('../assets/LilyPadIcon_Text.png')} style={styles.logo} />
            <TextInput
                style={styles.input}
                value={email}
                onChangeText={setEmail}
                placeholder="Email"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCompleteType="email"
                autoCorrect={false}
            />
            <TextInput
                style={styles.input}
                value={password}
                onChangeText={setPassword}
                placeholder="Password"
                secureTextEntry
                autoCapitalize="none"
                autoCorrect={false}
            />
            <TouchableOpacity onPress={handleAuth} style={styles.basicButton}>
              <Text style={styles.basicButtonText}>{isSigningUp ? 'Sign Up' : 'Sign In'}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setIsSigningUp(!isSigningUp)} style={styles.basicButton}>
              <Text style={styles.basicButtonText}>{isSigningUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}</Text>
            </TouchableOpacity>
            {error ? <Text style={styles.errorText}>{"Sorry but there was an error signing in/up, please try again."}</Text> : null}
        </View>
    );
};

const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E8E8E8'
    },
    title: {
        fontSize: 32,
        marginBottom: '10%',
        fontWeight: '900',
        color: colours.primary,
    },
    subtitle: {
        fontSize: 18,
        marginBottom: 10,
    },
    input: {
        width: '90%',
        height: '10%',
        padding: '2%',
        marginBottom: '2%',
        borderWidth: 2,
        borderColor: colours.primary,
        borderRadius: 5,
        fontSize: 18,
        fontWeight: '700'
    },
    basicButton: {
      width: '45%',
      height: '10%',
      padding: '1%',
      marginBottom: '2%',
      backgroundColor: colours.primary,
      borderRadius: 12,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: '700',
    },
    basicButtonText: {
      padding: 5,
      fontSize: 18,
      fontWeight: '700',
      color: 'white',
      textAlign: 'center'
    },
    errorText: {
        color: 'red',
        marginBottom: 20,
    },
    logo: {
        width: width*0.6,
        height: height*0.25,
        aspectRatio: 1.57,
        resizeMode: 'contain',
        marginTop: '5%',
        marginBottom: '2.5%'
    },
});

export default SignIn;
