import React from 'react';
import { View, Text, Button, StyleSheet, ScrollView, Image } from 'react-native';
import LottieView from 'lottie-react-native';


const colours = {
    primary: '#12CC8E'
}

const AboutPage = () => {
  return (
    <ScrollView style={styles.container}>
      <Image
        source={require("../assets/boulderingsquamish.png")}
        style={styles.headerImage}
      />
      <Text style={styles.title} >{"Welcome to LilyPad - Your Adventure Starts Here!"}</Text>
      <Text style={styles.text} >{"LilyPad is more than just a service; it's a community built around the thrill and passion of outdoor climbing adventures. We provide a unique platform connecting climbers with the essential gear they need, wherever they need it. With LilyPad, climbers can rent crash pads locally, reducing travel burdens and enhancing accessibility to various climbing spots."}</Text>
      <Text style={styles.title} >{"Our Mission"}</Text>
      <Text style={styles.text} >{"Our mission is to make climbing more accessible and sustainable for everyone. Whether you're a seasoned climber or just starting out, LilyPad enables you to explore new heights without the heavy investment in gear. We believe that by sharing resources, we can make climbing a more inclusive and environmentally friendly sport."}</Text>
      <Text style={styles.title} >{"Why LilyPad?"}</Text>

      <View style={{ paddingHorizontal: 20, marginVertical: 10 }}>
        <View style={styles.section}>
          <View style={styles.animationContainer}>
              <LottieView 
                  source={require('../assets/lotties/access.json')} 
                  autoPlay 
                  loop 
                  style={styles.lottieAnimation} 
              />
          </View>
          <View style={styles.textContainer}>
              <Text style={styles.subtitle} >{"Accessibility:"}</Text>
              <Text style={styles.subtext} >{"Easily find and rent crash pads near your climbing destination. Our user-friendly platform simplifies the process of finding the right gear at the right time."}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.animationContainer}>
              <LottieView 
                  source={require('../assets/lotties/community.json')} 
                  autoPlay 
                  loop 
                  style={styles.lottieAnimation} 
              />
          </View>
          <View style={styles.textContainer}>
              <Text style={styles.subtitle} >{"Community-Driven:"}</Text>
              <Text style={styles.subtext} >{"LilyPad is built by climbers for climbers. Operating as a nonprofit, LilyPad focuses solely on enhancing your climbing experience."}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.animationContainer}>
              <LottieView 
                  source={require('../assets/lotties/cost.json')} 
                  autoPlay 
                  loop 
                  style={styles.lottieAnimation} 
              />
          </View>
          <View style={styles.textContainer}>
              <Text style={styles.subtitle} >{"Cost-Effective:"}</Text>
              <Text style={styles.subtext} >{"Save money on gear that you might use only occasionally. Renting with LilyPad is easy on your wallet and practical."}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.animationContainer}>
              <LottieView 
                  source={require('../assets/lotties/green.json')} 
                  autoPlay 
                  loop 
                  style={styles.lottieAnimation} 
              />
          </View>
          <View style={styles.textContainer}>
              <Text style={styles.subtitle} >{"Sustainability:"}</Text>
              <Text style={styles.subtext} >{"By sharing resources, we reduce environmental impact, promoting a sustainable approach to the sport we love."}</Text>
          </View>
        </View>
      </View>


      <Text style={styles.title} >{"Join Our Community"}</Text>
      <Text style={styles.text} >{"Become part of a growing community of climbers who value adventure, sustainability, and camaraderie. Whether you're looking to rent a crash pad or you have one to share, LilyPad is your go-to platform. Let's make climbing accessible for all and support each other in conquering new peaks!"}</Text>
      <Text style={styles.title} >{"Contact Us"}</Text>
      <Text style={styles.text} >{"Have questions, suggestions, or want to join us? We'd love to hear from you! Reach out to lilypadclimbing@gmail.com."}</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingBottom: 100,
    },
    contentContainer: {
        padding: 20, 
        alignItems: 'center', 
        justifyContent: 'center', 
        alignContent: 'center'
    },
    headerImage: {
      width: '100%', 
      height: '40vh',
      aspectRatio: 1.5, 
    },  
    title: {
        fontSize: 32,
        marginBottom: 5,
        marginTop: 30,
        paddingHorizontal: 20,
        paddingVertical: 5,
        fontWeight: '900',
        color: colours.primary,
    },
    text: {
        fontSize: 18,
        paddingHorizontal: 30,
        paddingVertical: 5,
        textAlign: 'left'
    },
      section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    animationContainer: {
        width: 100,
        height: 100
    },
    lottieAnimation: {
        width: 100,
        height: 100
    },
    textContainer: {
        flex: 1,
        marginLeft: 10
    },
    subtitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: colours.primary
    },
    subtext: {
        fontSize: 16,
        color: '#666'
    },  
    input: {
        width: '60%',
        height: '10%',
        padding: '1%',
        marginBottom: '2%',
        borderWidth: 2,
        borderColor: colours.primary,
        borderRadius: 5,
        fontSize: 18,
        fontWeight: '700'
    },
    basicButton: {
      width: '30%',
      height: '10%',
      padding: '1%',
      marginBottom: '2%',
      backgroundColor: colours.primary,
      borderRadius: 12,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      fontWeight: '700',
    },
    basicButtonText: {
      fontSize: 18,
      fontWeight: '700',
      color: 'white'
    },
    errorText: {
        color: 'red',
        marginBottom: 20,
    },
})


export default AboutPage;
