import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Image, StyleSheet, ScrollView } from 'react-native';
import { onSnapshot, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase-config';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

const HomePage = () => {
  const [crashpads, setCrashpads] = useState([]);

  const icon = new L.Icon({
    iconUrl: require('../assets/LilyPadIcon.png'),
    iconSize: [60, 60],
    iconAnchor: [12, 12],
    popupAnchor: [1, -34],
    shadowSize: [60, 60]
  });

  useEffect(() => {
    // Establishing the listener
    const unsubscribe = onSnapshot(collection(firestore, 'crashpads'), (querySnapshot) => {
      const fetchedCrashpads = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCrashpads(fetchedCrashpads);
    });

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <ScrollView style={styles.container}>
      <MapContainer center={[49.701085, -123.152275]} zoom={13} style={styles.map}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {crashpads.map(pad => (
          <Marker
            key={pad.id}
            position={[pad.location.latitude, pad.location.longitude]}
            icon={icon}
          >
            <Popup>
              <View style={styles.popupContent}>
                <img src={pad.imageUrl} alt="Crashpad" style={styles.popupImage} />
                <View style={styles.popupText}>
                  <Text style={styles.descriptionText}>{pad.description}</Text>
                  <Text style={styles.amountText}>${pad.dollarAmount}</Text>
                  <Text style={styles.addressText}>{pad.address}</Text>
                  <Text style={styles.details}>{`${pad.firstName} - ${pad.phone}`}</Text>
                </View>
              </View>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      {crashpads.map(item => (
        <View key={item.id} style={styles.itemContainer}>
          <Image source={{ uri: item.imageUrl }} style={styles.image} />
          <View style={styles.textContainer}>
            <Text style={styles.description}>{item.description}</Text>
            <Text style={styles.details}>{`$${item.dollarAmount} - ${item.address}`}</Text>
            <Text style={styles.details}>{`${item.firstName} - ${item.phone}`}</Text>
          </View>
        </View>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#E8E8E8',
  },
  contentContainer: {
    padding: 20, 
    alignItems: 'center', 
    justifyContent: 'center', 
    alignContent: 'center'
  },
  itemContainer: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    padding: 20,
    borderRadius: 10,
    borderWidth: 0.5,
    width: '90%',
    borderColor: '#12CC8E',
    overflow: 'hidden',
    margin: 10,
    elevation: 3, // shadow for Android
    shadowColor: '#000', // shadow for iOS
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  image: {
    width: 100,
    height: 100,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  textContainer: {
    flex: 1,
    padding: 10,
  },
  description: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  details: {
    fontSize: 14,
    color: '#666',
    marginTop: 5,
  },
  descriptionText: {
    fontWeight: 'normal',
    fontSize: 14,
  },
  amountText: {
    fontWeight: 'bold',  // Make the dollar amount bold
    fontSize: 14,
  },
  addressText: {
    fontWeight: 'normal',
    fontSize: 14,
  },
  map: {
    marginTop: 30,
    padding: 10,
    width: '90%',
    height: '45vh',
    borderRadius: 10,
    borderColor: '#12CC8E',
    borderWidth: 2,
    alignSelf: 'center'
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 300,
    // minWidth: 100,
    // maxWidth: 300,
    alignItems: 'center',
    padding: 5  
  },
  popupImage: {
    width: 60,
    height: 60,
    objectFit: 'cover',  // Ensures the image covers the area without being stretched
    marginRight: 10
  },
  popupText: {
    padding: 5,
    marginLeft: 10,  // Add space between the image and the text
    textAlign: 'left',  // Align text to the left
    display: 'flex',
    flexDirection: 'column',  // Stack the text vertically
    width: '80%',  // or another specific width like '300px'
    overflowWrap: 'break-word',  // Ensures wrapping at any character if necessary
    wordWrap: 'break-word',  // Deprecated but useful for older browsers  
  },
});

export default HomePage;
