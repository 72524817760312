import React, { useState, useEffect } from 'react';
import { View, Text, Button, Modal, TextInput, StyleSheet, TouchableOpacity, ScrollView, Image, FlatList, Alert } from 'react-native';
import { collection, query, onSnapshot, where, getDocs, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { firestore, storage, auth } from '../firebase-config';
import * as ImagePicker from 'expo-image-picker';
import LocationPicker from './LocationPicker';

const colours = {
    primary: '#12CC8E'
};

const ProfilePage = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [dollarAmount, setDollarAmount] = useState('');
    const [description, setDescription] = useState('');
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [image, setImage] = useState(null);
    const [user, setUser] = useState(null);
    const [crashpads, setCrashpads] = useState([]);

    const handleLocationSelect = (selectedLocation) => {
        console.log("Selected location:", selectedLocation);
        setLocation({
            latitude: selectedLocation.lat,
            longitude: selectedLocation.lng
        });
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(setUser);
        return unsubscribe; 
    }, []);


    useEffect(() => {
        let unsubscribe = () => {};
        if (auth.currentUser) {
          const q = query(collection(firestore, 'crashpads'), where("useruid", "==", auth.currentUser.uid));
          unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedCrashpads = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setCrashpads(fetchedCrashpads);
          }, (error) => {
            console.error("Failed to fetch crashpads:", error);
          });
        }
      
        return () => unsubscribe(); 
      }, [auth.currentUser]);  

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled) {
            console.log(result.assets[0].uri);
            setImage(result.assets[0].uri);
        }
    };

    const handleDelete = async (id, filePath) => {
        const imageRef = ref(storage, filePath); 
        try {
            await deleteObject(imageRef); 
            await deleteDoc(doc(firestore, 'crashpads', id)); 
            setCrashpads(crashpads.filter(crashpad => crashpad.id !== id)); 
            Alert.alert('Success', 'Crashpad deleted successfully');
        } catch (error) {
            console.error('Error removing crashpad:', error);
            Alert.alert('Error', 'Failed to delete crashpad');
        }
    };

    const handleSubmit = async () => {
        if (!dollarAmount || !description || !firstName || !phone || !address || !image || location.latitude === null || location.longitude === null ) return;

        try {
            const filePath = `crashpads/${Date.now()}`;
            const storageRef = ref(storage, filePath);
            const response = await fetch(image);
            const blob = await response.blob();
            const uploadResult = await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(uploadResult.ref);

            await addDoc(collection(firestore, 'crashpads'), {
                dollarAmount: parseFloat(dollarAmount),
                description: description,
                address: address,
                phone: phone, 
                firstName: firstName,
                location: location,
                imageUrl: downloadURL,
                filePath: filePath,
                useruid: auth.currentUser.uid
            });

            console.log('Crashpad successfully added!');
        } catch (error) {
            console.error('Error adding crashpad:', error);
        }

        setModalVisible(false);
    };

    return (
        <ScrollView style={styles.container} >
            {user ? (
                <>
                    <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.addButton}>
                        <Text style={{fontSize: 24, fontWeight: '900', color: 'white', marginRight: 10}}>{"+"}</Text>
                        <Text style={{fontSize: 20, fontWeight: '600', color: 'white'}}>{"Add Crashpad"}</Text>
                    </TouchableOpacity>
                    {crashpads.map(item => (
                        <View key={item.id} style={styles.itemContainer}>
                            <Image source={{ uri: item.imageUrl }} style={styles.image} />
                            <View style={styles.textContainer}>
                                <Text style={styles.description}>{item.description}</Text>
                                <Text style={styles.details}>{`$${item.dollarAmount} - ${item.address}`}</Text>
                                <Text style={styles.details}>{`${item.firstName} - ${item.phone}`}</Text>
                            </View>
                            <TouchableOpacity style={styles.deleteButton} onPress={() => handleDelete(item.id, item.imageUrl)}>
                                <Text style={styles.deleteButtonText}>X</Text>
                            </TouchableOpacity>
                        </View>
                    ))}
                    <Modal visible={modalVisible} transparent animationType="slide">
                        <View style={styles.modalContainer}>
                        <ScrollView style={styles.modalScroll}>

                            <View style={styles.modalContent}>
                                <TextInput
                                    placeholder="Dollar Amount"
                                    keyboardType="numeric"
                                    value={dollarAmount}
                                    onChangeText={setDollarAmount}
                                    style={styles.input}
                                />
                                <TextInput
                                    placeholder="Description"
                                    value={description}
                                    onChangeText={setDescription}
                                    style={styles.input}
                                />
                                <TextInput
                                    placeholder="Your First Name"
                                    value={firstName}
                                    onChangeText={setFirstName}
                                    style={styles.input}
                                />
                                <TextInput
                                    placeholder="Your Phone Number"
                                    value={phone}
                                    onChangeText={setPhone}
                                    style={styles.input}
                                />


                                <TextInput
                                    placeholder="Address"
                                    value={address}
                                    onChangeText={setAddress}
                                    style={styles.input}
                                />
                                <View style={{ width: '90%', height: '45%', padding: 10, margin: 5, justifyContent: 'center', alignSelf: 'center' }}>
                                    <LocationPicker onLocationSelect={handleLocationSelect} />
                                </View>

                                <TouchableOpacity onPress={pickImage} style={styles.modalButton}>
                                    <Text style={styles.modalButtonText}>{image ? 'Change Image' : 'Pick Image'}</Text>
                                </TouchableOpacity>
                                {image && <Image source={{ uri: image }} style={styles.previewImage} />}
                                <TouchableOpacity onPress={handleSubmit} style={styles.modalButton}>
                                    <Text style={styles.modalButtonText}>{'Submit'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => setModalVisible(false)} style={[styles.modalButton, { marginBottom: 225 }]}>
                                    <Text style={styles.modalButtonText}>{'Close'}</Text>
                                </TouchableOpacity>
                            </View>
                        </ScrollView>
                        </View>
                    </Modal>
                </>
            ) : (
                <Text style={styles.notsignedin}>Please sign in to add any crashpads to the marketplace</Text>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E8E8E8'
    },
    contentContainer: {
        padding: 20, 
        alignItems: 'center', 
        justifyContent: 'center', 
        alignContent: 'center'
    },
    notsignedin: {
        color: colours.primary,
        fontSize: 24,
        textAlign: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 50,
        fontWeight: '700',
    },
    itemContainer: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
        padding: 20,
        borderRadius: 10,
        borderWidth: 0.5,
        width: '90%',
        borderColor: '#12CC8E',
        overflow: 'hidden',
        margin: 10,
        elevation: 3, 
        shadowColor: '#000', 
        shadowOffset: { width: 1, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    image: {
        width: 100,
        height: 100,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    textContainer: {
        flex: 1,
        padding: 10,
    },
    description: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    details: {
        fontSize: 14,
        color: '#666',
        marginTop: 5,
    },
    descriptionText: {
        fontWeight: 'normal',
        fontSize: 14,
    },
    amountText: {
        fontWeight: 'bold', 
        fontSize: 14,
    },
    addressText: {
        fontWeight: 'normal',
        fontSize: 14,
    },
    modalContainer: {
        flex: 1,
        alignItems: 'center', 
        justifyContent: 'center', 
        alignContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        overflow: 'scroll'
    },
    modalScroll: {
        width: '100%',
        paddingHorizontal: 20,
        maxHeight: '80%', 
        overflow: 'scroll'
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignSelf: 'center', 
        width: '100%',  
    },
    addButton: {
        backgroundColor: colours.primary,
        flexDirection: 'row', 
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        width: '50%',
        borderRadius: 8,
        padding: 5,
        paddingHorizontal: 20,
        marginVertical: 20
    },
    input: {
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,
        marginVertical: 10,
        width: '100%'
    },
    modalButton: {
        backgroundColor: colours.primary,
        width: '50%',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: 8,
    },
    modalButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    previewImage: {
        width: 100,
        height: 100,
        marginBottom: 20,
        alignSelf: 'center'
    },
    deleteButton: {
        position: 'absolute',
        right: -10,
        top: -10,
        backgroundColor: 'red',
        width: 40,
        height: 40,
        borderRadius: 12.5,
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.5
    },
    deleteButtonText: {
        color: 'white',
        fontSize: 20,
        padding: 5,
        fontWeight: '800',
    },
});

export default ProfilePage;
