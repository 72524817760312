import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAqPjF6jUn6NjgZLmtrHja6Q_Yqz2Mxkps",
  authDomain: "lilypad-97bea.firebaseapp.com",
  projectId: "lilypad-97bea",
  storageBucket: "lilypad-97bea.appspot.com",
  messagingSenderId: "679516551697",
  appId: "1:679516551697:web:b7437fab82637aa4610fb0",
  measurementId: "G-475HRNRJLM"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage }
